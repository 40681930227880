<template>
  <div class="home">
    <div class="home__zoom">
      <ui-button @click="zoom += 1" :disabled="zoom === maxZoom" color="white" icon>
        <img src="@/assets/svg/icon-plus-black.svg" alt="" />
      </ui-button>
      <ui-button @click="zoom -=1" :disabled="zoom === minZoom" color="white" icon>
        <img src="@/assets/svg/icon-minus-black.svg" alt="" />
      </ui-button>
    </div>
    <GmapMap
        ref="map"
        :center="{ lat: 55.755819, lng: 37.617644 }"
        :zoom="zoom"
        :options="{minZoom: minZoom, maxZoom: maxZoom}"
        map-type-id="terrain"
        style="width: 100vw; height: 100svh"
        @zoom="zoomIn"
    >
      <div v-for="(m, index) in getMarkersPos" :key="index">
        <GmapMarker
            v-if="getMapSortBy.status !== 'ESG'"
            :position="m.position"
            :clickable="true"
            :icon="getMarkerImage(m)"
            @click="handlerMarker(m)"
        />
      </div>
      <div v-for="(m, index) in getEsgMarkersPos" :key="getMarkersPos.length + index">
        <GmapMarker
            v-if="getMapSortBy.status === 'ESG' || getMapSortBy.status === 'All'"
            :position="m.position"
            :clickable="true"
            :icon="require('@/assets/svg/mapIcons/esg-blue.png')"
            @click="handlerEsgMarker(m)"
        />
      </div>
    </GmapMap>
    <div class="home__action">
      <router-link class="home__action-link" :to="getActionLink">
        <ui-button color="error" :disabled="getUser?.status === 'Rejected' || getUser?.status === 'New' || (isAuth && !getUser?.role)">{{ getActionText }}</ui-button>
      </router-link>
      <ui-button class="home__action-geolocation" icon>
        <img alt="" src="@/assets/svg/icon-geolocation.svg"/>
      </ui-button>
    </div>

    <ui-alert v-model="visibleAlert">
      <div class="ui-alert__wrap">
        <p class="ui-alert__title">{{ $t('attention') }}</p>
        <p class="ui-alert__text">{{ $t('please-authorize') }}</p>
      </div>
      <ui-button @click="auth">{{ $t('log-in') }}</ui-button>
    </ui-alert>

    <ui-alert v-model="visibleEsgAlert">
      <h2 class="ui-alert__title esg__alert-title">{{ $t('sustainable-development-project') }}</h2>
      <div class="ui-alert__wrap">
        <p class="ui-alert__text">{{ getNickname }}</p>
        <p class="ui-alert__text">{{ getEsgId.name || $t('empty') }}</p>
      </div>
      <div class="ui-alert__action">
        <ui-button @click="goToEsg">{{ $t('open-3') }}</ui-button>
        <ui-button @click="visibleEsgAlert=!visibleEsgAlert" color="white" >{{ $t('closed-3') }}</ui-button>
      </div>
    </ui-alert>
  </div>
</template>

<script>
import {getUserRole} from "@/utils/user";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'HomePage',
  components: {
    UiButton: () => import('@/components/ui/UiButton'),
    UiAlert: () => import('@/components/ui/UiAlert')
  },

  data() {
    return {
      getUserRole,
      visibleAlert: false,
      visibleEsgAlert: false,
      minZoom: 3,
      maxZoom: 15,
      zoom: 10,
      sortBy: {},
      activeEsgId: null
    }
  },
  computed: {
    ...mapGetters(['getMapOrders', 'getUser', 'isAuth', 'getEsg', 'getEsgId', 'getMapSortBy']),

    getNickname() {
      return this.getEsgId.createdBy?.role === 'Inspector' || this.getEsgId.createdBy?.role === 'Lawyer' ? this.getUserName(this.getEsgId.createdBy.lastName, this.getEsgId.createdBy.firstName, this.getEsgId.createdBy?.middleName) : '' ||
      this.getEsgId.createdBy?.role === 'Company' ? this.getEsgId.createdBy?.companyName : '' ||
      this.getEsgId.createdBy?.role === 'Government' ? this.getEsgId.createdBy?.companyName : this.getEsgId.createdBy?.role === 'Active' ? this.getEsgId.createdBy?.login : ''
    },

    getMarkersPos() {
      return this.getMapOrders.map((val) => {
        return  {
          ...val,
          position: {
            lat: Number(val.lat),
            lng: Number(val.lng),
          }
        }
      })
    },

    getEsgMarkersPos() {
      return this.getEsg.map((val) => {
        return  {
          ...val,
          position: {
            lat: Number(val.lat),
            lng: Number(val.lng),
          }
        }
      })
    },

    getActionText () {
      return this.isAuth && this.getUser?.role === 'Active' ? this.$t('my-requests') : this.isAuth && this.getUser?.role !== 'Admin' ? this.$t('work-requests') : this.isAuth && this.getUser?.role === 'Admin' ? this.$t('incident-requests') : this.$t('log-in')
    },

    getActionLink () {
      return this.isAuth && this.getUser?.status !== 'Rejected' && this.getUser?.status !== 'New' && this.getUser?.role ? '/work-requests' : !this.isAuth ? '/login' : ''
    },

    isModerator() {
      return this.getUser?.role === 'Moderator' || this.getUser?.role === 'Admin'
    },
  },

  methods: {
    ...mapMutations(['setMapOrders', 'setEsg', 'setMapSort']),
    ...mapActions(['reqGetOrders', 'esg', 'reqLogout', 'esgId']),

    zoomIn(e) {
      console.log(e, 'eee');
    },

    getOrdersList() {
      if(this.getMapSortBy.status !== 'ESG') {
        this.reqGetOrders({
          take: 500,
          damageRangeId: this.getMapSortBy.damageRangeId ? this.getMapSortBy.damageRangeId : null,
          status: this.isModerator && this.getMapSortBy.status === 'All' ? ['Accepted', 'Closed', 'Moderation'] :
              this.getMapSortBy.status !== 'ESG' && this.getMapSortBy.status !== 'All' ? [this.getMapSortBy.status] : ['Accepted', 'Closed']
        }).then((res) => {
          this.setMapOrders(res.data)
        })
      }
    },

    getEsgOrders () {
      this.esg({
        take: 500,
        status: this.isModerator ? ['Accepted', 'Closed', 'Moderation'] : ['Accepted', 'Closed'],
      }).then((res) => {
        this.setEsg(res.data)
      })
    },

    handlerMarker(val) {
      if(this.getUser) {
        this.$router.push(`/work-requests/${val.id}`)
      }
      else {
        this.visibleAlert = true
      }
    },

    handlerEsgMarker(val) {
      if(this.getUser) {
        this.esgId({id: val.id}).then(() => {
          this.activeEsgId = val.id
          this.visibleEsgAlert = true
        })
      }
      else {
        this.visibleAlert = true
      }
    },

    goToEsg() {
      this.$router.push(`/esg/${this.activeEsgId}`)
    },

    getMarkerImage(val) {
      let image = null

      switch (val.status) {
        case 'Accepted':
          image = require(`@/assets/svg/mapIcons/e-red-${val.damageRangeColorCode ? val.damageRangeColorCode : '#E21F1F'}.png`)
          break;
        case 'Closed':
          image = require('@/assets/svg/mapIcons/e-green.png')
          break;
        case 'Moderation':
          image = require('@/assets/svg/mapIcons/e-orange.png')
          break;
        default:
          break
      }
      return image
    },

    auth() {
      this.visibleAlert = false;
      this.$router.push('/login');
    }
  },

  mounted() {
    setTimeout(() => {
      console.log(this.$refs.map, 'mappppp');
    }, 3000)
    if(localStorage.getItem('registerStep') === '2') {
      this.reqLogout()
    }
  },

  created() {
    if(!this.getMapSortBy.id) {
      setTimeout(() => {
        this.setMapSort({id: 0, text: 'Все', status: 'All'})
      }, 1000)
    }
  },

  watch: {
    // isModerator: {
    //   immediate: true,
    //   handler() {
    //     this.getOrdersList()
    //   }
    // },

    getMapSortBy: {
      handler() {
        this.getOrdersList()
        this.getEsgOrders()
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.home {
  position: relative;

  &__zoom {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 50px;
    z-index: 10;

    @media (max-width: 768px) {
      right: 20px;
      gap: 6px;
    }
  }

  ::v-deep .vue-map-container {
    .vue-map {
      height: 100%;
    }
    .gm-control-active, .gm-svpc, .gmnoprint, .gm-style-cc {
      display: none;
    }
  }

  &__action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    position: absolute;
    bottom: 50px;
    padding: 0 50px;
    width: 100%;
    gap: 20px;

    @media (max-width: 1200px) {
      padding: 0 20px;
      bottom: 30px;
    }

    @media (max-width: 520px) {
      flex-direction: column-reverse;
      align-items: flex-end;
    }

    &-geolocation {
      background: black !important;
      position: absolute;
      right: 50px;
      padding: 4px 4px 0 0 !important;

      @media (max-width: 768px) {
        bottom: 80px;
        right: 20px;
      }

      &:hover {
        background: black !important;
      }
    }

    &-link {
      max-width: 597px;
      width: 100%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -0%);

      @media (max-width: 768px) {
        position: static;
        transform: translate(0%, 0%);
        margin: 0 auto;
      }
    }
  }
}
.esg__alert-title {
  color: #E21F1F;
}
::v-deep a[href="https://maps.google.com/maps?ll=55.755819,37.617644&z=10&t=p&hl=ru&gl=US&mapclient=apiv3"] {
  display: none !important;
}
</style>
